@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.linear-background {
  background-image: url("./assests/images/jumbotron-bg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 50px;
  height: 30px;
}

.text-headding {
  opacity: 100;
  font-family: "Russo One", sans-serif;
  font-size: 4rem;
  color: #1e272e;
}

.text-headding-quote {
  opacity: 100;
  font-family: "Russo One", sans-serif;
  font-size: 2rem;
  color: #1e272e;
}
.footer-text-headding {
  opacity: 100;
  font-family: "Russo One", sans-serif;
  font-size: 2rem;
  color: #1e272e;
}

.jumbotron-body-text {
  font-family: "Montserrat", sans-serif;
  /* font-weight: bold; */
  margin-top: 10px;
  font-size: 15px;
  color: black;
}
.row-content {
  align-items: center;
  align-self: center;
  min-height: 400px;
  border-bottom: 1px ridge;
}

.image-align-center {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}

.align-this-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog {
  max-width: none;
}

.small-text {
  font-size: 16px;
}

.model-row {
  border-bottom: 1px ridge;
}

.model-image {
  height: 200px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #2f3640;
}

.service-card:hover .overlay {
  opacity: 1;
}

.overlay-text {
  text-align: center;
  display: table-cell;
}

.small-text {
  font-size: 13px;
}

.projects-bg {
  background-image: url("./assests/images/projects-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
}

.project-text-headding {
  opacity: 100;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  color: #1e272e;
}

.project1-bg {
  background-image: url("./assests/images/project1-bg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.clients-bg {
  background-image: url("./assests/images/clients-bg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.center-img {
  margin: auto;
}

.map-style {
  height: 450px;
  border: 0;
}

.project-title {
  font-family: "Alfa Slab One", cursive;
  font-size: 1.6rem;
}

.project-text {
  font-family: "Lobster", cursive;
}

strong {
  color: #4b7bec;
}

.home1-bg {
  background-image: url("./assests/images/quote-bg.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hr-center {
  text-align: center;
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.image-height {
  height: 395px;
  width: auto;
  background-position: center;
  background-size: auto;
}
.image-less-height {
  height: 350px;
  width: auto;
  background-position: center;
  background-size: auto;
}

/* .gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
} */

/* @media (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

@media (max-width: 400px) {
  .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
} */

.gallery {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 10px;
}
.gallery-01 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 5vw);
  grid-gap: 10px;
}

.gallery0001 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 5vw);
  grid-gap: 10px;
}
.gallery10 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(11, 5vw);
  grid-gap: 10px;
}

.gallery-20 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 5vw);
  grid-gap: 10px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 5;
}

.gallery__item--2 {
  grid-column-start: 6;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 5;
}

.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 10;
}
.gallery__item--4 {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 5;
  grid-row-end: 10;
}
.gallery__item--01 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
}
.gallery__item--02 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 5;
}

.gallery__item--03 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 5;
}
.gallery__item--001 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 9;
}
.gallery__item--002 {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 9;
}
.gallery__item--003 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 5;
}
.gallery__item--004 {
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 5;
  grid-row-end: 9;
}

.gallery__item--0001 {
  grid-column: 1/2;
  grid-row: 1/6;
}
.gallery__item--0002 {
  grid-column: 2/3;
  grid-row: 1/6;
}
.gallery__item--0003 {
  grid-column: 3/4;
  grid-row: 1/6;
}
.gallery__item--0004 {
  grid-column: 1/4;
  grid-row: 6/11;
}

.gallery__item--10 {
  grid-column: 1/2;
  grid-row: 1/5;
}
.gallery__item--20 {
  grid-column: 2/3;
  grid-row: 1/5;
}
.gallery__item--30 {
  grid-column: 1/2;
  grid-row: 5/9;
}
.gallery__item--40 {
  grid-column: 3/4;
  grid-row: 1/7;
}
.gallery__item--50 {
  grid-column: 2/3;
  grid-row: 5/12;
}
.gallery__item--60 {
  grid-column: 1/2;
  grid-row: 9/12;
}
.gallery__item--70 {
  grid-column: 3/4;
  grid-row: 7/12;
}

.gallery__item--0201 {
  grid-column: 1/2;
  grid-row: 1/6;
}
.gallery__item--0202 {
  grid-column: 2/3;
  grid-row: 1/6;
}

.gallery__item:hover {
  transform: scale(0.95);
  transition: all 0.2s ease-in;
  opacity: 0.75;
}

@media (max-width: 500px) {
  .gallery {
    grid-template-columns: 2;
    grid-template-rows: repeat(auto-fit, 5vw);
  }

  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 5;
  }

  .gallery__item--2 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 5;
    grid-row-end: 8;
  }

  .gallery__item--3 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 8;
    grid-row-end: 11;
  }
  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 11;
    grid-row-end: 14;
  }

  .gallery__item--01 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .gallery__item--02 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 5;
    grid-row-end: 9;
  }

  .gallery__item--03 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 9;
    grid-row-end: 13;
  }

  /* HIGHWAY MATERIAL TESTING */

  .gallery__item--001 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .gallery__item--002 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 5;
    grid-row-end: 9;
  }
  .gallery__item--003 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 9;
    grid-row-end: 13;
  }
  .gallery__item--004 {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 13;
    grid-row-end: 17;
  }

  /* LABORATORY TESTING */

  .gallery0001 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(auto-fit 5vw);
    grid-gap: 10px;
  }
  .gallery__item--0001 {
    grid-column: 1/10;
    grid-row: 1/12;
  }
  .gallery__item--0002 {
    grid-column: 1/10;
    grid-row: 12/14;
  }
  .gallery__item--0003 {
    grid-column: 1/10;
    grid-row: 14/18;
  }
  .gallery__item--0004 {
    grid-column: 1/10;
    grid-row: 18/22;
  }

  /* NDT TEST FACILITIES */

  .gallery__item--10 {
    grid-column: 1/10;
    grid-row: 1/12;
  }
  .gallery__item--20 {
    grid-column: 1/10;
    grid-row: 12/16;
  }
  .gallery__item--30 {
    grid-column: 1/10;
    grid-row: 16/20;
  }
  .gallery__item--40 {
    grid-column: 1/10;
    grid-row: 20/24;
  }
  .gallery__item--50 {
    grid-column: 1/10;
    grid-row: 24/28;
  }
  .gallery__item--60 {
    grid-column: 1/10;
    grid-row: 28/32;
  }
  .gallery__item--70 {
    grid-column: 1/10;
    grid-row: 32/36;
  }

  /* TRAINING COURSES */

  .gallery__item--0201 {
    grid-column: 1/10;
    grid-row: 1/6;
  }
  .gallery__item--0202 {
    grid-column: 1/10;
    grid-row: 6/12;
  }

  .text-headding {
    opacity: 100;
    font-family: "Russo One", sans-serif;
    font-size: 40px;
    color: #1e272e;
  }
  .text-headding-quote {
    opacity: 100;
    font-family: "Russo One", sans-serif;
    font-size: 20px;
    color: #1e272e;
  }
}

.gallery__item {
  position: relative;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gallery-text {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 55px;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  bottom: 0px;
  /* left: 16px; */
}
